<template>
  <div v-bind:style="{ minHeight: minHeight + 'px' }">

    <!-- #page-header -->
    <div id="page-header" class="cover -small" data-image-src="assets/img/bg/kontakt.jpg">
      <div class="container">

        <div class="-wrapper">
          <div class="_inner">

            <h1 v-translate>Ďakujeme za registráciu</h1>

          </div>
        </div>

      </div>
    </div>
    <!-- /#page-header -->

    <!-- .section -->
    <section class="section">
      <div class="container">
        <h2 class="text-center" v-if="already_active_check"><translate>Váš účet je už aktívny.</translate></h2>
        <h2 class="text-center" v-if="!already_active_check"><translate>Váš účet bol úspešne aktivovaný. Pokračujte na prihlásenie</translate></h2>

        <ul class="bttns text-center">
          <li><a target="_blank" href="https://moj.webpartner.sk/login" v-bind:title="$gettext('Prihlásenie')" class="bttn -orange -small" v-translate>Prihlásenie</a></li>
        </ul>
      </div>
    </section>
    <!-- /.section -->


  </div>
</template>

<script>
export default {
  name: 'registration',
    metaInfo () {
        return {
            title: this.$gettext('registration_confirmed_title'),
            titleTemplate: '%s | Webpartner',
            htmlAttrs: {
                lang: 'sk-SK',
                amp: undefined
            },
            meta: [
                {name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW'}
            ],
            link: [
                {rel: 'canonical', href: 'https://webpartner.sk/potvrdena-registracia'}
            ]
        }
    },
    data() {
        return {
            window: {
                height: 0
            },
            footerHeight: 0,
            minHeight: 0,
            already_active_check: false
        }
    },
    created: function() {
        this.check();
        window.addEventListener('resize', this.handleResize)
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    mounted() {
        this.handleResize();
        this.footerHeight = this.$parent.$refs.mainFooter.$refs.footerElement.clientHeight;
        if(this.window.height > 200) {
            this.minHeight = this.window.height - this.footerHeight;
        }
    },
    /*beforeRouteEnter(to, from, next) {
        if (typeof to.query.key === "undefined" || typeof to.query.user === "undefined") {
            next({name: 'home'});
        }
    },*/
    methods: {
        handleResize() {
            this.window.height = window.innerHeight;
        },
        check() {
            const axios = require('axios');
            axios.post("https://api.controlpanel.sk/wp-activate-account", this.$route.params).then(response => {
                if(response.data.success == true) {
                    // je to OK
                } else {
                    if(response.data.already_active == true) {
                        this.already_active_check = true;
                    } else {
                        this.$router.push({name: 'home'});
                    }
                }
            }).catch(() => {
                this.$router.push({name: 'home'});
            });
        }
    }
}
</script>
